import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: "https://bdddbba5da58ab3c2b7a4b8d26edfefa@o4507567782952960.ingest.us.sentry.io/4507567787737088",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.feedbackIntegration({
      // Additional SDK configuration goes in here, for example:
      colorScheme: "dark",
    }),
  ],
  tracesSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
});
