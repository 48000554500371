interface state {
  emoji: string;
  bgcolor: string;
}
interface Config {
  dayOffest: number;
  correct: state;
  incorrect: state;
  seed: string;
  currentDay: number;
}
export const config: Config = {
  dayOffest: 19802,
  currentDay: Math.floor(Date.now() / 1000 / 60 / 60 / 24),
  correct: {
    emoji: "🟩",
    bgcolor: "bg-green-500",
  },
  incorrect: {
    emoji: "🟥",
    bgcolor: "bg-red-500",
  },
  seed: "aadvd",
};
