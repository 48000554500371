import { config } from "./config";

interface saveData {
  latestDate: number;
  stats: { [key: string]: Array<number> };
}

export default class SaveManager {
  data: saveData;
  constructor() {
    if (!localStorage.getItem("saveData")) {
      this.data = {
        latestDate: config.currentDay,
        stats: {},
      };
    } else {
      this.data = JSON.parse(
        localStorage.getItem("saveData") ||
          JSON.stringify({
            latestDate: config.currentDay,
            stats: {},
          }),
      );
    }
    if (this.data.latestDate < config.currentDay) {
      this.data.latestDate = config.currentDay;
    }
    if (this.data.stats[config.currentDay] === undefined) {
      this.data.stats[config.currentDay] = [];
    }
  }
  save() {
    localStorage.setItem("saveData", JSON.stringify(this.data));
  }
  load() {
    this.data = JSON.parse(
      localStorage.getItem("saveData") ||
        JSON.stringify({
          latestDate: config.currentDay,
          stats: [],
        }),
    );
  }
  reset() {
    this.data = {
      latestDate: config.currentDay,
      stats: {},
    };
    this.save();
  }
  addGuess(guess: number) {
    this.data.stats[config.currentDay].push(guess);
    this.save();
  }
  getStats() {
    return this.data.stats;
  }
  getToday() {
    return this.data.stats[config.currentDay] || [];
  }
}
