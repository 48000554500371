import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import isMobile from "is-mobile";

import SaveManager from "@/saveManager";

export default function Navbar() {
  const save = new SaveManager();

  const stats = save.getStats();

  // console.log(stats);

  const reactStats = (
    <>
      average Guesses to complete:{" "}
      {Object.keys(stats).length > 0
        ? Math.round(
            Object.values(stats).reduce((a, b) => a + b.length, 0) /
              Object.keys(stats).length,
          )
        : 0}
    </>
  );

  return (
    <nav className="flex flex-row justify-between items-center p-5">
      <div className="flex flex-row gap-5 items-center">
        <img
          src="https://placehold.co/40"
          className="w-10 h-10"
          alt="Logo"
        ></img>
        <a href="/">Home</a>
        <a href="/changelog">Changelog</a>
        <a href="/faq">Faq</a>
        <a href="https://discord.gg/YRRcXXRzca" target="_blank">
          Discord
        </a>
        {isMobile() ? (
          <Drawer>
            <DrawerTrigger>Stats</DrawerTrigger>
            <DrawerContent>
              <DrawerHeader>
                <DrawerTitle>Stats</DrawerTitle>
                <DrawerDescription>{reactStats}</DrawerDescription>
              </DrawerHeader>
            </DrawerContent>
          </Drawer>
        ) : (
          <Dialog>
            <DialogTrigger>Stats</DialogTrigger>
            <DialogContent>
              <DialogHeader>
                <DialogTitle>Stats</DialogTitle>
                <DialogDescription>{reactStats}</DialogDescription>
              </DialogHeader>
            </DialogContent>
          </Dialog>
        )}
      </div>
      {/* <div className="flex flex-row gap-5 items-center">Right side links</div> */}
    </nav>
  );
}
